import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import {
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  notification,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en";
import React, { useEffect, useState } from "react";
import "../App.css";
import { Project, employeeApi } from "../api";
import { employeeId } from "../utils/userDetails";

dayjs.locale("en");

const ApplyLeave = ({ isModalOpen, closeModal, leaveStatus }) => {

  const CheckboxGroup = Checkbox.Group;
  const { Text } = Typography;
  const [api, contextHolder] = notification.useNotification();
  const [employeeProject, setEmployeeProject] = useState([]);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const openNotification = (message, icon) => {
    api.open({
      message: message,
      duration: 2,
      icon: icon,
    });
  };
  const today = dayjs().format("YYYY-MM-DD");

  const differenceInDays = (start, end) => {
    const diffInMilliseconds = dayjs(end).diff(dayjs(start));
    const diffInDays =
      Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24)) + 1;
    return diffInDays;
  };

  const LeaveTypes = {
    FULL_DAY: 1,
    HALF_DAY: 2,
    HOLIDAY: 3,
  };

  const initialLeaveState = {
    startDate: dayjs(),
    endDate: dayjs(),
    totalDays: differenceInDays(dayjs(), dayjs()),
    leaveType: LeaveTypes.FULL_DAY,
  };

  const [leave, setLeave] = useState(initialLeaveState);
  const [errorMessageEnd, setErrorMessageEnd] = useState("");
  const [errorMessageStart, setErrorMessageStart] = useState("");
  const [hideItem, setHideItem] = useState(true);
  const [checkedList, setCheckedList] = useState([]);
  const [conflictDate, setConflictDate] = useState([]);
  const [applyLeaveData, setApplyLeaveData] = useState([]);

  useEffect(() => {
    if (!isModalOpen) return;
    setLeave(initialLeaveState);
    Project.getEmployeeProject({ employeeId: employeeId })
      .then((response) => {
        const Projects = response.projects.map(({ projectName, projectId }) => {
          return { label: projectName, value: projectId };
        });
        setEmployeeProject(Projects);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [isModalOpen]);

  const checkAll = employeeProject.length
    ? employeeProject?.length === checkedList.length
    : false;
    
  const onChange = (list) => {
    setCheckedList(list);
  };
  
  const onCheckAllChange = (e) => {
    const employeeList = employeeProject.map((item) => item.value);
    setCheckedList(e.target.checked ? employeeList : []);
  };

  useEffect(() => {
    if (leave.leaveType === LeaveTypes.HALF_DAY) setHideItem(false);
    else {
      setHideItem(true);
    }
  }, [leave.leaveType]);

  const setStartDate = (value) => {
    if (value) {
      if (leave.leaveType === 2) {
        const newLeave = {
          ...leave,
          startDate: dayjs(value),
          endDate: "",
          totalDays: undefined,
        };
        setLeave(newLeave);
        return;
      }
      const formattedDate = dayjs(value);
      const endDate = dayjs(leave.endDate);
      const diffInDays = differenceInDays(value, leave.endDate);
      const newLeave = {
        ...leave,
        startDate: formattedDate,
        totalDays: diffInDays,
      };
      setLeave(newLeave);

      if (formattedDate.isAfter(endDate)) {
        setErrorMessageStart("Start date cannot be greater than End date");
        setErrorMessageEnd("");
      } else {
        setErrorMessageStart("");
        setErrorMessageEnd("");
      }
    } else {
      setLeave({
        ...leave,
        startDate: "",
        totalDays: undefined,
      });
    }
  };

  const disableDate = (current) => {
    const today = dayjs();
    const oneMonthAgo = today.subtract(1, 'month');
    const twoMonthsLater = today.add(2, 'month');
    return current && (current < oneMonthAgo.startOf('month') || current > twoMonthsLater.endOf('month'));
  };

  const setEndDate = (value) => {
    if (value) {
      const formattedEndDate = dayjs(value);
      const startDate = dayjs(leave.startDate);
      const endDate = formattedEndDate;
      const diffInDays = differenceInDays(leave.startDate, value);

      const newLeave = {
        ...leave,
        endDate: value,
        totalDays: diffInDays,
      };
      setLeave(newLeave);
      if (dayjs(endDate).isBefore(dayjs(startDate))) {
        setErrorMessageEnd("End date cannot be less than Start date");
        setErrorMessageStart("");
      } else {
        setErrorMessageEnd("");
        setErrorMessageStart("");
      }
    } else {
      setLeave({
        ...leave,
        endDate: "",
        totalDays: undefined,
      });
    }
  };

  const formattedDates = (dates) => {
    const parsedDate = dayjs(dates, "YYYY/M/D");
    const formattedDate = parsedDate.format("D/M/YYYY");

    return formattedDate;
  };

  const handleLeaveApplied = async (data, del = false) => {
    try {
      const response = await employeeApi.applyLeave({
        ...data,
        deleteData: del,
      });
      openNotification(
        "Leave Applied Successfully !!",
        <CheckCircleFilled style={{ color: "#52c41a" }} />
      );
      leaveStatus(true);
    } catch (error) {
      if (error?.response?.data.conflicts) {
        console.log("error", error);
        setErrorModalVisible(true);
        const dates = Object.keys(error.response.data.conflicts).map(
          (dateString) => {
            const date = formattedDates(
              dateString.split("-").map(Number).join("/")
            );
            return date;
          }
        );
        setConflictDate(dates);
      } else {
        openNotification(
          error?.response?.data?.message || "Leave not applied ",
          <CloseCircleFilled style={{ color: "#ff4d4f" }} />
        );
        console.error("Error applying leave:", error);
      }
    } finally {
      closeModal();
      setCheckedList([]);
    }
  };
  
  const handleOk = () => {
    if (
      !leave.startDate || leave.leaveType === 2
        ? leave.endDate
        : !leave.endDate ||
          (leave.leaveType === 0 ? "" : errorMessageStart) ||
          (leave.leaveType === 0 ? "" : errorMessageEnd)
    ) {
      return;
    }
    let obj = {
      startDate: leave.startDate.format("YYYY-MM-DD"),
      endDate:
        leave.leaveType === 2
          ? leave.startDate.format("YYYY-MM-DD")
          : leave.endDate.format("YYYY-MM-DD"),
      leaveType: leave.leaveType,
      reason: "sick leave",
      status: "",
      deleteData: false,
      employeeId: employeeId,
      projectIds: checkedList,
    };

    setApplyLeaveData(obj);
    handleLeaveApplied(obj);
  };
  const handleCancel = () => {
    setLeave({
      startDate: dayjs(),
      endDate: dayjs(),
      totalDays: null,
      leaveType: LeaveTypes.FULL_DAY,
    });
    setErrorMessageStart("");
    setErrorMessageEnd("");
    setCheckedList([]);
    closeModal();
  };

  const handleCheckboxChange = (value) => {
    const newLeaveType =
      leave.leaveType === value ? LeaveTypes.FULL_DAY : value;

    if (
      newLeaveType === LeaveTypes.HALF_DAY ||
      newLeaveType === LeaveTypes.HOLIDAY ||
      newLeaveType === LeaveTypes.FULL_DAY
    ) {
      if (dayjs(leave.startDate).isAfter(dayjs(leave.endDate))) {
        setErrorMessageStart("Start date cannot be greater than End date");
      } else {
        setErrorMessageStart("");
      }
      if (dayjs(leave.endDate).isBefore(dayjs(leave.startDate))) {
        setErrorMessageEnd("End date cannot be less than Start date");
      } else {
        setErrorMessageEnd("");
      }
    }
    if (value === 2) {
      setLeave((prevLeave) => ({
        ...prevLeave,
        leaveType: value,
        endDate: "",
      }));
      return;
    }
    if (value === 3 || value === 1) {
      setLeave({
        startDate: dayjs(),
        endDate: dayjs(),
        totalDays: differenceInDays(dayjs(), dayjs()),
        leaveType: value,
      });
      return;
    }
    setLeave((prevLeave) => ({
      ...prevLeave,
      leaveType: prevLeave.leaveType === value ? LeaveTypes.FULL_DAY : value,
    }));
  };
  const handleErrorOk = () => {
    handleLeaveApplied(applyLeaveData, true);
    setErrorModalVisible(false);
  };

  return (
    <>
      <Modal
        title={
          <div style={{ width: "100%", textAlign: "center", fontSize: "16px" }}>
            Apply Leave
          </div>
        }
        style={{ fontSize: "20px" }}
        centered={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add"
        okButtonProps={{
          disabled: checkedList.length === 0,
        }}
      >
        <Form
          style={{ paddingTop: "1vh" }}
          layout="vertical"
          initialValues={{
            layout: "vertical",
            remember: true,
          }}
        >
          <Flex>
            <Row gutter={[8, 8]} style={{ width: hideItem ? "100%" : "50%" }}>
              <Col span={12}>
                <span>
                  Start Date
                  {<span style={{ color: "red", fontSize: "15px" }}>*</span>}
                </span>
                <Form.Item name="selectDate">
                  <DatePicker
                    style={{ width: "200%" }}
                    placeholder="Start Date"
                    value={leave.startDate}
                    onChange={(value) => setStartDate(value)}
                    allowClear={false}
                    disabledDate={disableDate}
                  />
                  {errorMessageStart &&
                    leave.leaveType !== LeaveTypes.HALF_DAY && (
                      <span
                        style={{
                          color: "red",
                          whiteSpace: "nowrap",
                          fontSize: "11px",
                        }}
                      >
                        {errorMessageStart}
                      </span>
                    )}
                </Form.Item>
              </Col>
            </Row>
            {hideItem && (
              <Row gutter={[8, 8]} style={{ width: "100%" }}>
                <Col span={12}>
                  <span>
                    End Date
                    {<span style={{ color: "red", fontSize: "15px" }}>*</span>}
                  </span>
                  <Form.Item name="endDate">
                    <DatePicker
                      style={{ width: "200%" }}
                      placeholder="End Date"
                      value={leave.endDate}
                      onChange={(value) => setEndDate(value)}
                      allowClear={false}
                      disabledDate={disableDate}
                    />
                    {errorMessageEnd && (
                      <span
                        style={{
                          color: "red",
                          whiteSpace: "nowrap",
                          fontSize: "11px",
                        }}
                      >
                        {errorMessageEnd}
                      </span>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Flex>
          {hideItem && (
            <Flex vertical style={{ width: "100%" }}>
              <span>Total Number of Days</span>
              <Form.Item>
                <Input
                  name="total"
                  value={leave.totalDays || ""}
                  placeholder="Total Number of Days"
                  disabled
                />
              </Form.Item>
            </Flex>
          )}
          <Row gutter={[8, 8]} style={{ marginTop: 2 }}>
            <Col span={8}>
              <Checkbox
                checked={leave.leaveType === LeaveTypes.FULL_DAY}
                onChange={() => handleCheckboxChange(LeaveTypes.FULL_DAY)}
              >
                Full Day
              </Checkbox>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <Checkbox
                checked={leave.leaveType === LeaveTypes.HALF_DAY}
                onChange={() => handleCheckboxChange(LeaveTypes.HALF_DAY)}
              >
                Half Day Leave
              </Checkbox>
            </Col>
          </Row>
          <Row gutter={[8, 8]} style={{ marginTop: 2 }}>
            <Col span={8}>
              <Checkbox
                checked={leave.leaveType === LeaveTypes.HOLIDAY}
                onChange={() => handleCheckboxChange(LeaveTypes.HOLIDAY)}
              >
                Holiday
              </Checkbox>
            </Col>
          </Row>
          <Form.Item
            name="Data"
            label={
              <Text style={{ fontSize: "16px" }}>
                Select Projects{" "}
                {<span style={{ color: "red", fontSize: "15px" }}>*</span>}
              </Text>
            }
            style={{ marginTop: "1rem" }}
          >
            <Checkbox
              onChange={onCheckAllChange}
              checked={checkAll}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                position: "relative",
                top: "-28px",
              }}
            >
              Select All
            </Checkbox>
            <CheckboxGroup
              style={{
                flexDirection: "column",
                position: "relative",
                top: "-1rem",
              }}
              options={employeeProject}
              value={checkedList}
              onChange={onChange}
            />
          </Form.Item>

          {contextHolder}
        </Form>
      </Modal>
      <Modal
        title="Alert"
        centered
        visible={errorModalVisible}
        onOk={handleErrorOk}
        okText="Add"
        onCancel={() => setErrorModalVisible(false)}
      >
        <p>You have logged tasks for the following dates:</p>
        <Flex vertical>
          {conflictDate?.map((date, index) => (
            <div key={index}>{date}</div>
          ))}
        </Flex>
        <div>Are you sure to apply leave on these date?</div>
      </Modal>
    </>
  );
};

export default ApplyLeave;
