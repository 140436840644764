import { Button, Flex, Modal, Select, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "../Styles/employeeTimesheet.css";
import { Project, employeeTimesheet } from "../api";
import TableComponent from "../utils/TableComponent";
import { employeeId } from "../utils/userDetails";
import { convertTime, formatNewDate } from "../utils/dateFormat";
import ChatCommentForm from "../components/Form/ChatCommentForm";

export default function Timesheet() {
  const monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [deliveryManagerId, setDeliveryManagerId] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const currentDate = moment();
  const [selectedMonth, setSelectedMonth] = useState(currentDate.month());
  const [currentYear, setCurrentYear] = useState(currentDate.year());
  const [projectArray, setProjectArray] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorShown, setErrorShown] = useState(false);
  const [refreshChat, setRefreshChat] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(null);

  const columns = [
    {
      title: "Date",
      dataIndex: "logDate",
      key: "logDate",
      align: "center",
      width: "15%",
      render: (_, { date }) => {
        return {
          children: <p>{formatNewDate(date)}</p>,
          props: {
            style: {
              background:
                moment(date).day() === 0 || moment(date).day() === 6
                  ? "pink"
                  : "white",
            },
          },
        };
      },
    },
    {
      title: "Time Logged",
      dataIndex: "timeWorked",
      key: "hours",
      align: "center",
      width: "15%",
      render: (_, { timeWorked }) => convertTime(timeWorked),
    },
    {
      title: "Description",
      dataIndex: "task_performed",
      key: "desc",
      align: "left",
      render: (text, record, index) => {
        if (text?.length !== 0 && !record.leave_type) {
          return <div dangerouslySetInnerHTML={{ __html: text }} />;
        } else if (record.leave_type) {
          return (
            <Flex gap={"0.5rem"} vertical>
              {record.leave_type && (
                <Flex>
                  <b style={{ color: "red" }}>Leave:&nbsp; </b>
                  <div style={{ color: "red" }}>{record.type}</div>
                </Flex>
              )}

              {record.type !== "FULL DAY" && record.type !== "HOLIDAY" ? (
                <div dangerouslySetInnerHTML={{ __html: text }} />
              ) : (
                <></>
              )}
            </Flex>
          );
        } else {
          return "";
        }
      },
    },
  ];

  useEffect(() => {
    Project.getEmployeeProject({ employeeId: employeeId })
      .then((response) => {
        const allProjects = response.projects.map(
          ({ projectName, projectId }) => {
            return { label: projectName, value: projectId };
          }
        );
        setProjectArray(allProjects);
        setSelectedProject(
          allProjects.length > 0 ? allProjects[0].value : null
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const timeSheet = async () => {
    try {
      setLoading(true);
      const response = await employeeTimesheet.UserActivity({
        projectId: selectedProject,
        employeeNo: employeeId,
        year: currentYear,
        month: selectedMonth,
      });
      setDeliveryManagerId(response.deliveryManager);
      setApprovalStatus(response.approvalStatus);
      const filteredData = response.data;
      
      if (filteredData) {
        filteredData?.sort((a, b) => new Date(a.logDate) - new Date(b.logDate));
        const newDataSource = filteredData.map((item) => ({
          date: item.logDate,
          task_performed: item.comment,
          timeWorked: item.minutesWorked,
          leave_type: item.isLeave,
          type: item.leaveType,
        }));
        setDataSource(newDataSource);
      }
    } catch (error) {
      if (!errorShown) {
        setErrorShown(true);
        if (error.message === "Network Error") {
          message.error("Network connection failed. Try again.");
        } else {
          message.error("An error occurred while fetching data.");
        }
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (
      (selectedMonth || selectedMonth === 0) &&
      selectedProject &&
      currentYear
    ) {
      timeSheet();
    }
  }, [selectedProject, selectedMonth, currentYear]);
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const handleApproval = (e) => {
    setIsModalVisible(true);
    setRefreshChat((prev) => !prev);
  };
  const handleYearChange = (value) => {
    setCurrentYear(value);
  };
  const handleMonthChange = (value) => {
    setSelectedMonth(value - 1);
  };
  const handleProjectChange = (value) => {
    setSelectedProject(value);
  };
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      total: dataSource.length,
    });
  };

  return (
    <Flex vertical gap={"2rem"} style={{ padding: 24 }}>
      <Flex justify="space-between" align="center" gap="2rem" wrap="wrap">
        <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
          <div>
            <div className="selectDropDown">Year</div>
            <Select
              placeholder="Select Year"
              defaultValue={currentYear}
              onChange={handleYearChange}
              size="large"
              style={{ width: "100px", textAlign: "left" }}
            >
              {Array.from(
                { length: 5 },
                (_, i) => currentDate.year() - 1 + i
              ).map((year) => (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <div
              style={{
                fontSize: "16px",
                marginBottom: "0.5rem",
                color: "#307BFF",
                textAlign: "start",
              }}
            >
              Month
            </div>
            <Select
              placeholder="Select Month"
              defaultValue={selectedMonth + 1}
              onChange={handleMonthChange}
              size="large"
              style={{ width: "120px", textAlign: "left" }}
            >
              {monthArray.map((month, index) => (
                <Select.Option key={index} value={index + 1}>
                  {month}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <div
              style={{
                fontSize: "16px",
                marginBottom: "0.5rem",
                color: "#307BFF",
                textAlign: "start",
              }}
            >
              Project
            </div>
            <Select
              placeholder="Select Project"
              value={selectedProject}
              onChange={handleProjectChange}
              size="large"
              style={{ width: "250px", textAlign: "left" }}
              options={projectArray}
            />
          </div>
        </div>
        <div>
          <Button
            type="primary"
            size="large"
            onClick={handleApproval}
            disabled={approvalStatus === 3}
            style={{
              marginTop: "2rem",
              backgroundColor:
                approvalStatus === 3
                  ? "Green"
                  : approvalStatus === 2
                  ? "#1677ff"
                  : "#faad14",
              color:
                approvalStatus === 3 || approvalStatus === 4 ? "white" : "",
            }}
          >
            {approvalStatus === 3
              ? "Approved"
              : approvalStatus === 2
              ? "Save as draft"
              : "Send For Approval"}
          </Button>
        </div>
      </Flex>
      <div
        className="formScroll"
        style={{ maxHeight: "75vh", overflowY: "scroll" }}
      >
        <TableComponent
          loading={loading}
          columns={columns}
          rows={dataSource}
          scroll={{ x: 1400 }}
          pagination={pagination}
          onChange={handleTableChange}
          locale={{ emptyText: "No data available for given input" }}
        />
      </div>
      <Modal
        title={
          <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
            Comments
          </div>
        }
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        width={600}
      >
        <ChatCommentForm
          visible={isModalVisible}
          hide={setIsModalVisible}
          refreshChat={refreshChat}
          projectId={selectedProject}
          year={currentYear}
          approverNo={deliveryManagerId}
          month={selectedMonth}
        />
      </Modal>
    </Flex>
  );
}
